import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Helmet from "react-helmet";

import Layout from "components/Layout";
import Container from "components/Container";

import mail from "assets/images/SVG/mail.svg";
import linked from "assets/images/SVG/linkedin.svg";
import github from "assets/images/SVG/github.svg";
// import dribbble from "assets/images/SVG/dribbble.svg";
// import insta from "assets/images/SVG/instagram.svg";
import fb from "assets/images/SVG/facebook.svg";

const ContactPage = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          author
          linkedIn
          github
          emailAddress
          facebook
        }
      }
    }
  `);

  return (
    <Layout pageName="contact">
      <Helmet>
        <title>Jesus Vera - Contact</title>
      </Helmet>
      <Container>
        <h1>Contact</h1>
        <p>Let's get in touch!</p>
        <div className="contact__icon">
          <a
            href={data.site.siteMetadata.linkedIn}
            target="_blank"
            rel="noopener noreferrer"
          >
            <svg className="contact__icon-item">
              <use xlinkHref={`#${linked.id}`} />
            </svg>
          </a>

          <a
            href={data.site.siteMetadata.github}
            target="_blank"
            rel="noopener noreferrer"
          >
            <svg className="contact__icon-item">
              <use xlinkHref={`#${github.id}`} />
            </svg>
          </a>

          {/* <a
            href={data.site.siteMetadata.github}
            target="_blank"
            rel="noopener noreferrer"
          >
            <svg className="contact__icon-item">
              <use xlinkHref={`#${dribbble.id}`} />
            </svg>
          </a> */}

          <a
            href={`mailto:${data.site.siteMetadata.emailAddress}`}
            rel="noopener noreferrer"
          >
            <svg className="contact__icon-item">
              <use xlinkHref={`#${mail.id}`} />
            </svg>
          </a>

          <a
            href={data.site.siteMetadata.facebook}
            target="_blank"
            rel="noopener noreferrer"
          >
            <svg className="contact__icon-item">
              <use xlinkHref={`#${fb.id}`} />
            </svg>
          </a>
        </div>
      </Container>
    </Layout>
  );
};

export default ContactPage;
